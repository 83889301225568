/* Card: Modular summary
-------------------------------------------------- */

.tnt-grid {
    grid-column-gap: 30px;
    grid-row-gap: 0px;
}

@media screen and (min-width: 768px) {

    .tnt-grid.modular-summary .card-grid {
        width: 158px;
    }

    .tnt-grid.modular-summary {
        display: -ms-grid;
        display: grid; 
        justify-items: stretch;
    }

    .tnt-grid.modular-summary.main-left.three-col {
        -ms-grid-columns: 1fr min-content min-content;
         grid-template-columns: auto min-content min-content;
    }

    .tnt-grid.modular-summary.main-left.two-col,
    .tnt-grid.modular-summary.main-center.two-col{
        -ms-grid-columns: 1fr min-content;
        grid-template-columns: auto min-content;
    }

    .tnt-grid.modular-summary.main-center.three-col {
        -ms-grid-columns: min-content 1fr min-content;
        grid-template-columns: min-content auto min-content;
    }

    .tnt-grid.modular-summary.main-right.three-col {
        -ms-grid-columns: min-content min-content 1fr;
        grid-template-columns: min-content min-content auto;
    }

    .tnt-grid.modular-summary.main-right.two-col {
        -ms-grid-columns: min-content 1fr;
        grid-template-columns: min-content auto;
    }

}
    @media screen and (min-width: 992px) {

    .tnt-grid.modular-summary .card-grid {
        width: 213px;
    }
    
    /* modular-summary size jump one breakpoint later */
    .modular-summary.three-col .card-img-sm .card .card-container > .card-image,
    .modular-summary.three-col .card-img-md .card .card-container > .card-image,
    .modular-summary.three-col .card-img-lg .card .card-container > .card-image,
    .modular-summary.three-col .card-img-xlg .card .card-container > .card-image {
        width: 100%;
        float: none;
        margin-left: 0;
        margin-right: 0;
    }
    
    .modular-summary.three-col .card.has-image > .card-container > .card-body {
        margin-left: 0;
        margin-right: 0;
        padding-top: 15px;
    }

}

@media screen and (min-width: 1200px) {

    .tnt-grid.modular-summary .card-grid {
        width: 263px;
    }

}